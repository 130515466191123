html {
  overflow: hidden;
}

body {
  background-color: #e3e4e5;
}

.threadrNav {
  background-color: #eb5757 !important;
  /* color: #6c757d; */
  color: #fff;
}

.navbar-nav a {
  color: #fff !important;
  margin-left: 10px;
}

.navbar-nav .active {
  color: #00ffeb !important;
  text-decoration: underline;
}

.navbar-brand {
  color: #fff !important;
  padding: 0 !important;
}

.dropdown-item {
  color: #000 !important;
}
/* 6f42c144 */

.userNavDropdown a {
  color: #fff ;
}

.navHome {
  font-size: 140%;
}

.navHomeIcon {
  /* height: 0; */
  margin-left: 30px;
  margin-right: 10px;
  /* font-size: 140%; */
}

.navDrafts {
  font-size: 140%;
}

.navDraftsIcon {
  margin-left: 40px;
  margin-right: 10px;
  /* font-size: 140%; */
}

.navSchedule {
  font-size: 140%;
}

.navScheduleIcon {
  margin-left: 40px;
  margin-right: 10px;
  /* font-size: 140%; */
}

.tweetContainer {
  /* display: flex; */
  border-width: 1px;
  border-color: #e3e4e5;
  background-color: #fff;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
  margin-left: 50px;
  padding-left: 40px;
  overflow: hidden;
  position: relative;
}

.tweetSidebar {
  width: 40px;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  /* border-radius: .25rem; */
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25em;
  position: absolute;
  margin-top: -10px;
}

.tweetProfileImage {
  width: 60px;
  /* display: inline-block; */
  float: left;
}

.tweetText {
  margin-left: 6px;
  text-align: left;
}

.tweetThreadButton {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.input-group-text {
  display: grid;
}

div.addImageModal {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 632px;
  /* max-height: 80vh; */
  height: 80vh;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  z-index: 3;
  overflow: hidden;
}
div.addImageModal.hide {
  display: none;
}
div.addImageModal__header {
  position: sticky;
  top: 0;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  background: #ffffff;
  z-index: 4;
}
div.addImageModal__header .right {
  margin-left: auto;
}
div.addImageModal__header .right.close {
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
}
div.addImageModal__header i.dropdown {
  margin-left: 8px;
  font-size: 16px;
  line-height: 32px;
}
div.addImageModal__body {
  padding: 1.25rem;
  overflow: auto;
}

div.loginModal {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 632px;
  max-height: 80vh;
  /* height: 80vh; */
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  z-index: 3;
}
div.loginModal.hide {
  display: none;
}
div.loginModal__header {
  position: sticky;
  top: 0;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  background: #ffffff;
  z-index: 4;
}
div.loginModal__header .right {
  margin-left: auto;
}
div.loginModal__header .right.close {
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
}
div.loginModal__header i.dropdown {
  margin-left: 8px;
  font-size: 16px;
  line-height: 32px;
}
div.loginModal__body {
  padding: 1.25rem;
}

div.quoteTweetModal {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 632px;
  max-height: 80vh;
  /* height: 80vh; */
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  z-index: 3;
}
div.quoteTweetModal.hide {
  display: none;
}
div.quoteTweetModal__header {
  position: sticky;
  top: 0;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  background: #ffffff;
  z-index: 4;
}
div.quoteTweetModal__header .right {
  margin-left: auto;
}
div.quoteTweetModal__header .right.close {
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
}
div.quoteTweetModal__header i.dropdown {
  margin-left: 8px;
  font-size: 16px;
  line-height: 32px;
}
div.quoteTweetModal__body {
  padding: 1.25rem;
}

div.addVideoModal {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 632px;
  max-height: 80vh;
  /* height: 80vh; */
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  z-index: 3;
}
div.addVideoModal.hide {
  display: none;
}
div.addVideoModal__header {
  position: sticky;
  top: 0;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  background: #ffffff;
  z-index: 4;
}
div.addVideoModal__header .right {
  margin-left: auto;
}
div.addVideoModal__header .right.close {
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
}
div.addVideoModal__header i.dropdown {
  margin-left: 8px;
  font-size: 16px;
  line-height: 32px;
}
div.addVideoModal__body {
  padding: 1.25rem;
}

.savingDraft {
  margin-top: 10px;
}

/**
* Drafts
*/
.draftsWrapper {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%;
}

.draftContainer {
  width: 600px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 70px 450px 40px;
  grid-gap: 10px;
  padding: 10px;
}

.draftLeftCol {
  height: 100%;
  /* border-right: #6c757d;
  border-right-width: 1px;
  border-right-style: solid; */
}

.draftItemsContainer {
  margin-top: 40px;
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.draftImage {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 50px;
  /* border-right: #6c757d;
  border-right-width: 1px;
  border-right-style: solid; */
}

.draftTextWrapper {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
}

.draftTitle {
  font-weight: bold;
  font-size: 120%;
}

.draftText {
  height: 150px;
  /* overflow-y: auto; */
  overflow-y: hidden;
  padding-top: 10px;
}

.draftDate {
  padding-top: 90px;
  font-size: 12px;
  color: #6c757d;
}

.draftIconsWrapper {
  display: grid;
}

.draftEditIcon {
  margin: auto;
}

.draftDeleteIcon {
  margin: auto;
}

/**
* Sent Threads
*/
.sentThreadWrapper {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%;
  overflow: hidden;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.sentThreadThreadsWrapper {
  /* overflow-y: auto; */
  padding-right: 20px;
}

.sentThreadContainer {
  width: 520px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 200px 40px;
  grid-template-columns: 70px 380px 40px;
  /* grid-gap: 10px; */
  column-gap: 10px;
  padding: 10px;
}

.sentThreadLeftCol {
  height: 100%;
  /* border-right: #6c757d;
  border-right-width: 1px;
  border-right-style: solid; */
}

.sentThreadItemsContainer {
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.sentThreadImage {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 50px;
  /* border-right: #6c757d;
  border-right-width: 1px;
  border-right-style: solid; */
}

.sentThreadTextWrapper {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
}

.sentThreadTitle {
  font-weight: bold;
  font-size: 120%;
}

.sentThreadText {
  height: 150px;
  /* overflow-y: auto; */
  overflow-y: hidden;
  padding-top: 10px;
}

.sentThreadDate {
  padding-top: 90px;
  font-size: 12px;
  color: #6c757d;
}

.sentThreadIconsWrapper {
  display: grid;
}

.sentThreadEditIcon {
  margin: auto;
}

.retweetThreadIcon {
  margin: auto;
}

.sentThreadAddIcon {
  margin: auto;
}

.sentThreadDeleteIcon {
  margin: auto;
}

.sentThreadTotals {
  display: inline;
  border-top-color: #ebebeb;
  border-top-width: 1px;
  border-top-style: solid;
  grid-column: 1 / span 3;
  padding-top: 6px;
  margin-left: -10px;
}

/**
* View Sent Thread
*/
.viewSentThreadScrollContainer {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.viewSentThreadWrapper {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10px;
}

/**
* Loading Spinner
*/
.MuiCircularProgress-colorPrimary {
  color: '#eb5757';
}
.loadingSpinnerContainer {
  /* justify-content: flex-start; */
  /* padding-top: 7rem; */
  height: 100%;
  width: 100%;
}
.loadingSpinnerProgress {
  /* align-items: center;
  height: 50%;
  margin: auto; */
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000000;
}

.tweetImageDelete {
  position: absolute;
  cursor: pointer;
  /* width: 80px; */
  /* height: 80px; */
  margin-left: 45px;
  /* top: calc(100% - 50%); */
  z-index: 1;
}

.imgWrapper {
  display: inline-block;
  /* height: 80px; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/*
.imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  width: 100%;
  background-color: rgba(128,128,128,0.5);
} */

/* .imgWrapper img {width: 100%;} */

.ReactCrop {
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;
    max-width: 100%;
    background-color: #fff;
}

.ReactCrop__image {
    display: block;
    max-width: 100%;
    touch-action: manipulation;
    max-height: 540px;
}

.ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: none !important;
    touch-action: manipulation;
    border: 1px solid;
    border-image-source: url(data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==);
    border-image-slice: 1;
    border-image-repeat: repeat;
}